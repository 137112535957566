(function ($) {
  $(function () {
    
    // $("#herbShrooms .cardlist").slick({
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   dots: false,
    //   arrows: false,
    //   autoplay: true,
    //   autoplaySpeed: 2000,
    //   centerMode: true,
    //   centerPadding: "10px",
    // });

    // logo slider
    $('.autoslide').slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      nextArrow: "<img class='next-btn' src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMzOC4yNTQ4IDQ5IDQ5IDM4LjI1NDggNDkgMjVDNDkgMTEuNzQ1MiAzOC4yNTQ4IDEgMjUgMUMxMS43NDUyIDEgMSAxMS43NDUyIDEgMjVDMSAzOC4yNTQ4IDExLjc0NTIgNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMzOC4yNTQ4IDQ5IDQ5IDM4LjI1NDggNDkgMjVDNDkgMTEuNzQ1MiAzOC4yNTQ4IDEgMjUgMUMxMS43NDUyIDEgMSAxMS43NDUyIDEgMjVDMSAzOC4yNTQ4IDExLjc0NTIgNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPG1hc2sgaWQ9Im1hc2swXzUxN18xMjI3IiBzdHlsZT0ibWFzay10eXBlOmx1bWluYW5jZSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMTMiIHk9IjE3IiB3aWR0aD0iMjUiIGhlaWdodD0iMTYiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTI5Ljg5MyAxNy40MjhMMzYuNzUgMjQuMzg0QzM2LjkxMSAyNC41NDcgMzcuMDAyIDI0Ljc2OSAzNy4wMDIgMjVDMzcuMDAyIDI1LjIzMSAzNi45MTEgMjUuNDUzIDM2Ljc1IDI1LjYxNkwyOS44OTMgMzIuNTcyQzI5LjU1NyAzMi45MTIgMjkuMDE0IDMyLjkxMiAyOC42NzkgMzIuNTcyQzI4LjM0NCAzMi4yMzIgMjguMzQ0IDMxLjY4MSAyOC42NzkgMzEuMzQxTDM0LjA3NCAyNS44N0gxMy44NTdDMTMuMzg0IDI1Ljg3IDEzIDI1LjQ4IDEzIDI1QzEzIDI0LjUyIDEzLjM4NCAyNC4xMyAxMy44NTcgMjQuMTNIMzQuMDc0TDI4LjY3OSAxOC42NTlDMjguMzQ0IDE4LjMxOSAyOC4zNDQgMTcuNzY4IDI4LjY3OSAxNy40MjhDMjkuMDE0IDE3LjA4OCAyOS41NTcgMTcuMDg4IDI5Ljg5MyAxNy40MjhaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNTE3XzEyMjcpIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDM3LjgyN1YxMi4xNzNINDIuMDAyVjM3LjgyN0g4WiIgZmlsbD0iIzM1MzUzNSIvPgo8L2c+Cjwvc3ZnPgo='>",
      prevArrow: "<img class='prev-btn' src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMxMS43NDUyIDQ5IDEgMzguMjU0OCAxIDI1QzEgMTEuNzQ1MiAxMS43NDUyIDEgMjUgMUMzOC4yNTQ4IDEgNDkgMTEuNzQ1MiA0OSAyNUM0OSAzOC4yNTQ4IDM4LjI1NDggNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMxMS43NDUyIDQ5IDEgMzguMjU0OCAxIDI1QzEgMTEuNzQ1MiAxMS43NDUyIDEgMjUgMUMzOC4yNTQ4IDEgNDkgMTEuNzQ1MiA0OSAyNUM0OSAzOC4yNTQ4IDM4LjI1NDggNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPG1hc2sgaWQ9Im1hc2swXzUxN18xMjUzIiBzdHlsZT0ibWFzay10eXBlOmx1bWluYW5jZSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMTIiIHk9IjE3IiB3aWR0aD0iMjUiIGhlaWdodD0iMTYiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIwLjEwNyAxNy40MjgxQzIwLjQ0MyAxNy4wODgxIDIwLjk4NiAxNy4wODgxIDIxLjMyMSAxNy40MjgxQzIxLjY1NiAxNy43NjgxIDIxLjY1NiAxOC4zMTkxIDIxLjMyMSAxOC42NTkxTDE1LjkyNiAyNC4xMzAxSDM2LjE0M0MzNi42MTYgMjQuMTMwMSAzNyAyNC41MjAxIDM3IDI1LjAwMDFDMzcgMjUuNDgwMSAzNi42MTYgMjUuODcwMSAzNi4xNDMgMjUuODcwMUgxNS45MjZMMjEuMzIxIDMxLjM0MTFDMjEuNjU2IDMxLjY4MTEgMjEuNjU2IDMyLjIzMjEgMjEuMzIxIDMyLjU3MjFDMjAuOTg2IDMyLjkxMjEgMjAuNDQzIDMyLjkxMjEgMjAuMTA3IDMyLjU3MjFMMTMuMjUgMjUuNjE2MUMxMy4wODkgMjUuNDUzMSAxMi45OTggMjUuMjMxMSAxMi45OTggMjUuMDAwMUMxMi45OTggMjQuNzY5MSAxMy4wODkgMjQuNTQ3MSAxMy4yNSAyNC4zODQxTDIwLjEwNyAxNy40MjgxWiIgZmlsbD0id2hpdGUiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzUxN18xMjUzKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDIgMzcuODI3MVYxMi4xNzMxSDcuOTk4VjM3LjgyNzFINDJaIiBmaWxsPSIjMzUzNTM1Ii8+CjwvZz4KPC9zdmc+Cg=='>",
      // centerMode: true,
      autoplay: true,
      autoplaySpeed: 2000,
      variableWidth: false,
      responsive: [
          {
              breakpoint: 768,
              settings: {
                  slidesToShow: 1,
                  centerMode: true,
                  centerPadding: '100px',
              },
            },
            {
              breakpoint: 375,
              settings: {
                  slidesToShow: 1,
                  centerMode: true,
                  centerPadding: '100px',
                  
              }
          },
          
      ]
  });

  //blog carousel 

  $('.blog-carousel-slick').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    nextArrow: "<img class='next-btn' src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMzOC4yNTQ4IDQ5IDQ5IDM4LjI1NDggNDkgMjVDNDkgMTEuNzQ1MiAzOC4yNTQ4IDEgMjUgMUMxMS43NDUyIDEgMSAxMS43NDUyIDEgMjVDMSAzOC4yNTQ4IDExLjc0NTIgNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMzOC4yNTQ4IDQ5IDQ5IDM4LjI1NDggNDkgMjVDNDkgMTEuNzQ1MiAzOC4yNTQ4IDEgMjUgMUMxMS43NDUyIDEgMSAxMS43NDUyIDEgMjVDMSAzOC4yNTQ4IDExLjc0NTIgNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPG1hc2sgaWQ9Im1hc2swXzUxN18xMjI3IiBzdHlsZT0ibWFzay10eXBlOmx1bWluYW5jZSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMTMiIHk9IjE3IiB3aWR0aD0iMjUiIGhlaWdodD0iMTYiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTI5Ljg5MyAxNy40MjhMMzYuNzUgMjQuMzg0QzM2LjkxMSAyNC41NDcgMzcuMDAyIDI0Ljc2OSAzNy4wMDIgMjVDMzcuMDAyIDI1LjIzMSAzNi45MTEgMjUuNDUzIDM2Ljc1IDI1LjYxNkwyOS44OTMgMzIuNTcyQzI5LjU1NyAzMi45MTIgMjkuMDE0IDMyLjkxMiAyOC42NzkgMzIuNTcyQzI4LjM0NCAzMi4yMzIgMjguMzQ0IDMxLjY4MSAyOC42NzkgMzEuMzQxTDM0LjA3NCAyNS44N0gxMy44NTdDMTMuMzg0IDI1Ljg3IDEzIDI1LjQ4IDEzIDI1QzEzIDI0LjUyIDEzLjM4NCAyNC4xMyAxMy44NTcgMjQuMTNIMzQuMDc0TDI4LjY3OSAxOC42NTlDMjguMzQ0IDE4LjMxOSAyOC4zNDQgMTcuNzY4IDI4LjY3OSAxNy40MjhDMjkuMDE0IDE3LjA4OCAyOS41NTcgMTcuMDg4IDI5Ljg5MyAxNy40MjhaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNTE3XzEyMjcpIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDM3LjgyN1YxMi4xNzNINDIuMDAyVjM3LjgyN0g4WiIgZmlsbD0iIzM1MzUzNSIvPgo8L2c+Cjwvc3ZnPgo='>",
    prevArrow: "<img class='prev-btn' src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMxMS43NDUyIDQ5IDEgMzguMjU0OCAxIDI1QzEgMTEuNzQ1MiAxMS43NDUyIDEgMjUgMUMzOC4yNTQ4IDEgNDkgMTEuNzQ1MiA0OSAyNUM0OSAzOC4yNTQ4IDM4LjI1NDggNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMxMS43NDUyIDQ5IDEgMzguMjU0OCAxIDI1QzEgMTEuNzQ1MiAxMS43NDUyIDEgMjUgMUMzOC4yNTQ4IDEgNDkgMTEuNzQ1MiA0OSAyNUM0OSAzOC4yNTQ4IDM4LjI1NDggNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPG1hc2sgaWQ9Im1hc2swXzUxN18xMjUzIiBzdHlsZT0ibWFzay10eXBlOmx1bWluYW5jZSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMTIiIHk9IjE3IiB3aWR0aD0iMjUiIGhlaWdodD0iMTYiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIwLjEwNyAxNy40MjgxQzIwLjQ0MyAxNy4wODgxIDIwLjk4NiAxNy4wODgxIDIxLjMyMSAxNy40MjgxQzIxLjY1NiAxNy43NjgxIDIxLjY1NiAxOC4zMTkxIDIxLjMyMSAxOC42NTkxTDE1LjkyNiAyNC4xMzAxSDM2LjE0M0MzNi42MTYgMjQuMTMwMSAzNyAyNC41MjAxIDM3IDI1LjAwMDFDMzcgMjUuNDgwMSAzNi42MTYgMjUuODcwMSAzNi4xNDMgMjUuODcwMUgxNS45MjZMMjEuMzIxIDMxLjM0MTFDMjEuNjU2IDMxLjY4MTEgMjEuNjU2IDMyLjIzMjEgMjEuMzIxIDMyLjU3MjFDMjAuOTg2IDMyLjkxMjEgMjAuNDQzIDMyLjkxMjEgMjAuMTA3IDMyLjU3MjFMMTMuMjUgMjUuNjE2MUMxMy4wODkgMjUuNDUzMSAxMi45OTggMjUuMjMxMSAxMi45OTggMjUuMDAwMUMxMi45OTggMjQuNzY5MSAxMy4wODkgMjQuNTQ3MSAxMy4yNSAyNC4zODQxTDIwLjEwNyAxNy40MjgxWiIgZmlsbD0id2hpdGUiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzUxN18xMjUzKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDIgMzcuODI3MVYxMi4xNzMxSDcuOTk4VjM3LjgyNzFINDJaIiBmaWxsPSIjMzUzNTM1Ii8+CjwvZz4KPC9zdmc+Cg=='>",
    // centerMode: true,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                // centerMode: true,
                // centerPadding: '100px',
            }
        },
    ]
});


// team carousel 

$('.team-carousel-slick').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  nextArrow: "<img class='next-btn' src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMzOC4yNTQ4IDQ5IDQ5IDM4LjI1NDggNDkgMjVDNDkgMTEuNzQ1MiAzOC4yNTQ4IDEgMjUgMUMxMS43NDUyIDEgMSAxMS43NDUyIDEgMjVDMSAzOC4yNTQ4IDExLjc0NTIgNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMzOC4yNTQ4IDQ5IDQ5IDM4LjI1NDggNDkgMjVDNDkgMTEuNzQ1MiAzOC4yNTQ4IDEgMjUgMUMxMS43NDUyIDEgMSAxMS43NDUyIDEgMjVDMSAzOC4yNTQ4IDExLjc0NTIgNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPG1hc2sgaWQ9Im1hc2swXzUxN18xMjI3IiBzdHlsZT0ibWFzay10eXBlOmx1bWluYW5jZSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMTMiIHk9IjE3IiB3aWR0aD0iMjUiIGhlaWdodD0iMTYiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTI5Ljg5MyAxNy40MjhMMzYuNzUgMjQuMzg0QzM2LjkxMSAyNC41NDcgMzcuMDAyIDI0Ljc2OSAzNy4wMDIgMjVDMzcuMDAyIDI1LjIzMSAzNi45MTEgMjUuNDUzIDM2Ljc1IDI1LjYxNkwyOS44OTMgMzIuNTcyQzI5LjU1NyAzMi45MTIgMjkuMDE0IDMyLjkxMiAyOC42NzkgMzIuNTcyQzI4LjM0NCAzMi4yMzIgMjguMzQ0IDMxLjY4MSAyOC42NzkgMzEuMzQxTDM0LjA3NCAyNS44N0gxMy44NTdDMTMuMzg0IDI1Ljg3IDEzIDI1LjQ4IDEzIDI1QzEzIDI0LjUyIDEzLjM4NCAyNC4xMyAxMy44NTcgMjQuMTNIMzQuMDc0TDI4LjY3OSAxOC42NTlDMjguMzQ0IDE4LjMxOSAyOC4zNDQgMTcuNzY4IDI4LjY3OSAxNy40MjhDMjkuMDE0IDE3LjA4OCAyOS41NTcgMTcuMDg4IDI5Ljg5MyAxNy40MjhaIiBmaWxsPSJ3aGl0ZSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNTE3XzEyMjcpIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDM3LjgyN1YxMi4xNzNINDIuMDAyVjM3LjgyN0g4WiIgZmlsbD0iIzM1MzUzNSIvPgo8L2c+Cjwvc3ZnPgo='>",
  prevArrow: "<img class='prev-btn' src='data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMxMS43NDUyIDQ5IDEgMzguMjU0OCAxIDI1QzEgMTEuNzQ1MiAxMS43NDUyIDEgMjUgMUMzOC4yNTQ4IDEgNDkgMTEuNzQ1MiA0OSAyNUM0OSAzOC4yNTQ4IDM4LjI1NDggNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yNSA0OUMxMS43NDUyIDQ5IDEgMzguMjU0OCAxIDI1QzEgMTEuNzQ1MiAxMS43NDUyIDEgMjUgMUMzOC4yNTQ4IDEgNDkgMTEuNzQ1MiA0OSAyNUM0OSAzOC4yNTQ4IDM4LjI1NDggNDkgMjUgNDlaIiBzdHJva2U9IiMzNTM1MzUiLz4KPG1hc2sgaWQ9Im1hc2swXzUxN18xMjUzIiBzdHlsZT0ibWFzay10eXBlOmx1bWluYW5jZSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMTIiIHk9IjE3IiB3aWR0aD0iMjUiIGhlaWdodD0iMTYiPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTIwLjEwNyAxNy40MjgxQzIwLjQ0MyAxNy4wODgxIDIwLjk4NiAxNy4wODgxIDIxLjMyMSAxNy40MjgxQzIxLjY1NiAxNy43NjgxIDIxLjY1NiAxOC4zMTkxIDIxLjMyMSAxOC42NTkxTDE1LjkyNiAyNC4xMzAxSDM2LjE0M0MzNi42MTYgMjQuMTMwMSAzNyAyNC41MjAxIDM3IDI1LjAwMDFDMzcgMjUuNDgwMSAzNi42MTYgMjUuODcwMSAzNi4xNDMgMjUuODcwMUgxNS45MjZMMjEuMzIxIDMxLjM0MTFDMjEuNjU2IDMxLjY4MTEgMjEuNjU2IDMyLjIzMjEgMjEuMzIxIDMyLjU3MjFDMjAuOTg2IDMyLjkxMjEgMjAuNDQzIDMyLjkxMjEgMjAuMTA3IDMyLjU3MjFMMTMuMjUgMjUuNjE2MUMxMy4wODkgMjUuNDUzMSAxMi45OTggMjUuMjMxMSAxMi45OTggMjUuMDAwMUMxMi45OTggMjQuNzY5MSAxMy4wODkgMjQuNTQ3MSAxMy4yNSAyNC4zODQxTDIwLjEwNyAxNy40MjgxWiIgZmlsbD0id2hpdGUiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzUxN18xMjUzKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNDIgMzcuODI3MVYxMi4xNzMxSDcuOTk4VjM3LjgyNzFINDJaIiBmaWxsPSIjMzUzNTM1Ii8+CjwvZz4KPC9zdmc+Cg=='>",
  centerMode: true,
  // centerPadding: '100px',
  autoplay: false,
  autoplaySpeed: 2000,
  responsive: [
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,
              // centerMode: true,
              centerPadding: '20px',
          }
      },
  ]
});



    /* //logo slider
    $(".autoslide").slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "100px",
          },
        },
      ],
    });

    //Post
    $(".slider-featured-post").slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            dots: true,
            centerMode: false,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1,
            dots: true,
            centerMode: true,
            centerPadding: "65px",
          },
        },
      ],
    });

    // Business carousel
    $(".team-carousel-js").slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      autoplay: false,
      autoplaySpeed: 2000,
      prevArrow: $(".team-carousel-prev", this),
      nextArrow: $(".team-carousel-next", this),
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,
            centerMode: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            centerMode: false,
          },
        },
        {
          breakpoint: 560,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
    });
    //Hide carousel nav if less than 5 team members (as there are 4 items to scroll)
    if ($(".team-carousel-js .slick-slide").length < 5) {
      $(".team-carousel-nav").addClass("d-none");
    } */

    var totalItems = $('.item').length;
    var currentIndex = $('.carousel-item.active').data("count");

    // var currentIndex = $('div.active').index() + 1;
    $('.carousel-control-prev').click(function () {
    // $('#carouselExampleControls .carousel-control-prev').on('click', function() { 
      setTimeout(function() {
        currentIndex = $('.carousel-item.active').data("count");
        $('.num').text(''+currentIndex+'/'+totalItems+'');
      }, 800);
     console.log(currentIndex);
  });

  $('.carousel-control-next').click(function () {
  //  $('#carouselExampleControls .carousel-control-next').on('click', function() { 
      setTimeout(function() {
        currentIndex = $('.carousel-item.active').data("count");
        $('.num').text(''+currentIndex+'/'+totalItems+'');
      }, 800);

     console.log(currentIndex);
  });

  var myCarousel = document.getElementById('carouselExampleControls')

myCarousel.addEventListener('slid.bs.carousel', function () {
  setTimeout(function() {
    currentIndex = $('.carousel-item.active').data("count");
    $('.num').text(''+currentIndex+'/'+totalItems+'');
  }, 50);

  
})

  });
 
})(jQuery);
